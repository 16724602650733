<template>
  <div>
    <b-card>
      <div class="d-flex justify-content-between">
        <div>
          <button
            class="compact ui button"
            @click="refreshCommon"
          >
            <refresh-cw-icon
              size="12"
              class="custom-class"
              :stroke-width="3"
            />
            Tải lại
          </button>
          <button
            v-if="checkQuyenTraKetQuaNhieuHs"
            class="compact ui btn-primary button"
            :class="{disabled: selectedRows && selectedRows.length === 0}"
            @click="processMultipleDoc"
          >
            <corner-down-right-icon
              size="12"
              class="custom-class"
              :stroke-width="3"
            />
            Trả kết quả nhiều hồ sơ
          </button>
        </div>
        <div>
          <filter-icon
            size="20"
            class="cursor-pointer mb-1"
            :stroke-width="2"
            @click="showFormFilter"
          />
        </div>
      </div>

      <advanced-search
        v-show="formFilterShow"
        ref="formFilter"
        class="mt-2"
        @advanced-search="filterDanhSachHoSo"
      />
      <good-table
        ref="hoSo"
        class="mt-1"
        :class="checkAllQuyen ? '' : 'fixed-column'"
        :columns="getColumns"
        :rows="rows"
        :total="tongSoHoSo"
        :row-style="checkRowStyle"
        @selected-item="onSelectRow"
        @column-filter="filterDanhSachHoSo"
        @update-item="onUpdate"
        @delete-item="setItemUpdate"
        @page-change="pageChange"
      >
        <template
          slot="custom-row"
          slot-scope="props"
        >
          <div
            v-if="props.props.column.field === 'tenTrangThai'"
            class="custom-column"
          >
            <div>{{ formatDateTime(props.props.row.ngayNop) }}</div>
            <div>{{ formatDateTime(props.props.row.ngayHenTraKq) }}</div>
            <!-- eslint-disable vue/no-v-html -->
            <div v-html="props.props.row.tenLoaiTrangThai" />
            <!--eslint-enable-->
            <div
              v-if="props.props.row.thoiGianDenHanTraKQ"
              :class="props.props.row.thoiGianDenHanTraKQ.indexOf('Quá hạn') >= 0 ? 'text-red-cor' : 'text-green-cor'"
            >
              {{ props.props.row.thoiGianDenHanTraKQ }}
            </div>
          </div>
          <span v-if="props.props.column.field == 'code'">
            <a
              href="javascript:;"
              @click="detailHoSo(props.props.row)"
            >{{ `${props.props.row.code}` }}</a>
            <div>
              <cloud-icon
                size="15"
                class="custom-class"
              /> {{ props.props.row.kieuTiepNhan }}
              <div
                v-if="(props.props.row.isChoThuPhi || props.props.row.isCanThuPhi)"
                class="warning-class d-flex align-items-center"
                title="Vui lòng cập nhật tình trạng thu phí tại menu Hồ sơ chờ thu phí, lệ phí"
              >
                <x-circle-icon
                  size="16"
                />
                <span class="text-phi">Chưa nộp phí, lệ phí</span>
              </div>
            </div>
            <div v-if="props.props.row.dichVuChuyenPhatKetQua">
              <send-icon
                size="15"
                class="custom-class"
              /> Dịch vụ chuyển phát kết quả
            </div>
          </span>
          <div
            v-else-if="props.props.column.field === 'tenLoaiTrangThai'"
            class="custom-column"
          >
            <div v-if="props.props.row.tenTrangThai && !props.props.row.tenTrangThai.indexOf('Kết thúc')">
              <div class="text-yellow-cor">
                {{ props.props.row.tenTrangThai }}
              </div>

            </div>
            <div v-else>
              <div>{{ formatDateTime(props.props.row.thoiGianBatDauTrangThai) }}</div>
              <div>{{ formatDateTime(props.props.row.thoiGianKetThucTrangThai) }}</div>
              <div class="text-yellow-cor">
                {{ props.props.row.tenTrangThai }}
              </div>
              <div
                v-if="props.props.row.thoiGianDenHanKetThucTrangThai"
                :class="props.props.row.thoiGianDenHanKetThucTrangThai.indexOf('Còn hạn') >= 0 ? 'text-green-cor' : 'text-red-cor'"
              >
                {{ props.props.row.thoiGianDenHanKetThucTrangThai }}
              </div>
            </div>

          </div>
          <div v-else-if="props.props.column.field === 'chuHoSo_HoVaTen'">
            <div v-if="props.props.row.chuHoSo_HoVaTen">
              <user-icon size="14" /> {{ props.props.row.chuHoSo_HoVaTen }}
            </div>
            <div
              v-if="props.props.row.chuHoSo_DiaChiDayDu"
              class="text-top word-break"
            >
              <map-pin-icon size="14" /> {{ props.props.row.chuHoSo_DiaChiDayDu }}
            </div>
          </div>
          <div v-else-if="props.props.column.field === 'tenThuTucHanhChinh'">
            {{ props.props.row.tenThuTucHanhChinh }}
          </div>
        </template>
        <template
          slot="custom-filter"
          slot-scope="props"
        >
          <treeselect
            v-if="props.props.column.field === 'tenThuTucHanhChinh'"
            id="linhVuc"
            ref="tenThuTucHanhChinh"
            v-model="filter.dataTTHCId"
            v-format-v-select
            :default-options="danhSachThuTucHanhChinh"
            placeholder="Chọn thủ tục hành chính"
            :limit="0"
            multiple
            :limit-text="(count) => ` + ${count} lựa chọn`"
            no-options-text="Không có dữ liệu"
            no-results-text="Không có dữ liệu"
            loading-text="Đang tìm kiếm"
            :normalizer="normalizer"
            :match-keys="['label', 'label2']"
            class="vtreeselect-chooser"
            :load-options="onQueryChange"
            :async="true"
            :clear-on-select="true"
            :before-clear-all="clearTreeSelect"
            @select="$refs.tenThuTucHanhChinh.resetSearchQuery(); nextTickTreeSelect()"
            @deselect="nextTickTreeSelect()"
          >
            <label
              slot="option-label"
              slot-scope="{ node, shouldShowCount, count, labelClassName }"
              :class="labelClassName"
              :title="node.label"
            >
              {{ node.label }}
            </label>
          </treeselect>
          <treeselect
            v-else-if="props.props.column.field === 'tenLoaiTrangThai'"
            v-model="filter.trangThaiConHanQuaHan"
            v-format-v-select
            class="vtreeselect-chooser"
            :options="danhSachThoiHan"
            :limit="0"
            :limit-text="(count) => ` + ${count} lựa chọn`"
            no-options-text="Không có dữ liệu"
            no-results-text="Không có dữ liệu"
            placeholder="Chọn thời hạn"
            :clear-on-select="true"
            :before-clear-all="clearTreeSelect"
            @select="nextTickTreeSelect()"
            @deselect="nextTickTreeSelect()"
          />
          <treeselect
            v-else-if="props.props.column.field === 'tenTrangThai'"
            v-model="filter.loaiTrangThaiConHanQuaHan"
            v-format-v-select
            class="vtreeselect-chooser"
            :options="danhSachThoiHan"
            :limit="0"
            :limit-text="(count) => ` + ${count} lựa chọn`"
            no-options-text="Không có dữ liệu"
            no-results-text="Không có dữ liệu"
            placeholder="Chọn thời hạn"
            :clear-on-select="true"
            :before-clear-all="clearTreeSelect"
            @select="nextTickTreeSelect()"
            @deselect="nextTickTreeSelect()"
          />
        </template>
        <template
          slot="actions"
          slot-scope="props"
        >
          <div class="text-center">
            <eye-icon
              v-if="checkQuyenChiTiet"
              v-b-tooltip.hover.v-secondary
              title="Chi tiết"
              size="18"
              class="cursor-pointer mr-1"
              @click="detailHoSo(props.props.row)"
            />
            <printer-icon
              v-if="checkQuyenIn"
              v-b-tooltip.hover.v-secondary
              title="In"
              size="16"
              class="custom-class cursor-pointer"
              stroke="#606266"
              @click="onPrint(props.props.row)"
            />
          </div>
        </template>
      </good-table>
    </b-card>
    <xu-ly-ho-so
      ref="xuLyHoSo"
      @reload-page="filterDanhSachHoSo()"
    />
    <iframe
      id="printf"
      name="printf"
      class="d-none"
    />
    <bien-nhan-ho-so ref="bienNhanHoSo" />
    <common-modal
      ref="commonModal"
      :title="title"
      @handle-ok="handleOk"
    >
      <h5 class="text-center">
        Bạn chắc chắn muốn trả những hồ sơ này ?
      </h5>
      <div
        v-if="isShow"
        slot="footer"
      >
        <b-button
          variant="primary"
          size="sm"
          class="mr-1"
          @click="handleOk"
        >
          Đồng ý
        </b-button>
        <b-button
          variant="warning"
          size="sm"
          @click="$refs.commonModal.hideModal()"
        >
          Đóng
        </b-button>
      </div>
    </common-modal>
    <commonModal
      ref="printModal"
      :title="'Mẫu phiếu in'"
    >
      <b-list-group style="max-height: 350px; overflow: auto;">
        <b-list-group-item
          v-for="item in danhSachNhomPhieu"
          :key="item.id"
          href="#"
          @click="onClickToPrinter(item)"
        >{{ item.tenMauPhieu }}</b-list-group-item>
      </b-list-group>
      <div slot="footer">
        <div class="w-100">
          <b-button
            variant="danger"
            size="sm"
            class="float-right"
            @click="$refs.printModal.hideModal()"
          >
            Đóng
          </b-button>
        </div>
      </div>
    </commonModal>
    <common-modal
      ref="commonModalGQHS"
      :title="titleGQHS"
    >
      <h5 class="text-center">
        Trả KQ giải quyết hồ sơ thành công 2 hồ sơ
      </h5>
      <div
        v-if="isShow"
        slot="footer"
      >
        <b-button
          variant="warning"
          size="sm"
          @click="$refs.commonModalGQHS.hideModal()"
        >
          Đóng
        </b-button>
      </div>
    </common-modal>
    <b-modal
      id="InTheoBieuMau"
      ref="InTheoBieuMau"
      :hide-footer="true"
      :hide-header="true"
      :no-close-on-backdrop="true"
      size="lg"
    >
      <div
        v-if="mauPhieu"
        ref="docBody"
        v-html="mauPhieu.duongDan"
      />
      <div>
        <div style="text-align: right; margin-top: 1.5rem">
          <b-button
            style="margin-right: 1rem"
            variant="primary"
            size="sm"
            @click="onClickToPrint"
          >
            In biên nhận
          </b-button>
          <b-button
            style="margin-right: 1rem"
            variant="primary"
            size="sm"
            @click="onExportFileWord"
          >
            Kết xuất
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            @click="hide"
          >
            Đóng
          </b-button>
        </div>
      </div>
      <iframe
        id="printing-frame"
        name="print_frame"
        style="display:none;"
      />
    </b-modal>
  </div>

</template>
<script>
import { mapGetters } from 'vuex'
import {
  BCard,
  VBTooltip,
  BButton,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import XuLyHoSo from '@/modules/xu-ly-ho-so/components/common/XuLyHoSo.vue'
import commonModal from '@/modules/tiep-nhan-ho-so/components/modal/CommonModal.vue'
import GoodTable from '@/components/GoodTable.vue'
import AdvancedSearch from '@/components/AdvancedSearch.vue'
import BienNhanHoSo from '@/modules/tiep-nhan-ho-so/components/pages/truc-tiep/BienNhanHoSo.vue'
import _debounce from 'lodash/debounce'
import END_POINTS from '@/api/endpoints'
import { formatDateTime, removeDiacritical } from '@/utils/index'
import {
  FilterIcon,
  PrinterIcon,
  EyeIcon,
  UserIcon,
  CornerDownRightIcon,
  RefreshCwIcon,
  MapPinIcon,
  CloudIcon,
  XCircleIcon,
  SendIcon,
} from 'vue-feather-icons'
import addCssSemantic from '@/mixins/mixins'
import { PERMISSION_NAME } from '@/constants/constants'
import { hasPermission } from '@/utils/permission-utils'

export default {
  components: {
    GoodTable,
    commonModal,
    BButton,
    XuLyHoSo,
    CloudIcon,
    UserIcon,
    MapPinIcon,
    SendIcon,
    BienNhanHoSo,
    CornerDownRightIcon,
    RefreshCwIcon,
    PrinterIcon,
    BCard,
    EyeIcon,
    FilterIcon,
    AdvancedSearch,
    XCircleIcon,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [addCssSemantic],
  data() {
    return {
      hoSo: null,
      faMagnifyingGlass,
      danhSachNhomPhieu: null,
      mauPhieu: null,
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
        },
        {
          label: 'Mã biên nhận',
          field: 'code',
          width: '200px',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Tên thủ tục hành chính',
          field: 'tenThuTucHanhChinh',
          width: 'auto',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Chủ hồ sơ',
          field: 'chuHoSo_HoVaTen',
          width: '250px',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Trạng thái',
          field: 'tenTrangThai',
          width: '150px',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Chi tiết',
          field: 'tenLoaiTrangThai',
          width: '150px',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Thao tác',
          thClass: 'text-center',
          tdClass: 'vertical-align-middle',
          field: 'actions',
          width: '100px',
        },
      ],
      tongSoHoSo: 0,
      rows: [],
      lvCbx: [],
      isShow: false,
      title: 'Chú ý',
      titleGQHS: 'Thông báo',
      showCanThuPhi: true,
      showChoThuPhi: true,
      selectedRows: [],
      filter: {
        dataTTHCId: [],
        dataLinhVucId: [],
        fulltextsearch: '',
        chuHoSo: '',
        maHoSo: '',
        diaChiChuHoSo: '',
        thoiGianTiepNhanTu: '',
        thoiGianTiepNhanDen: '',
        thoiGianHenTraTu: '',
        thoiGianHenTraDen: '',
        trangThaiConHanQuaHan: null,
        loaiTrangThaiConHanQuaHan: null,
        trangThaiHoSo: [],
        loaiTrangThaiHoSo: [],
        navCode: this.$route.meta.code,
        pageNumber: 1,
        pageSize: 10,
      },
      danhSachThoiHan: [
        {
          id: 1,
          label: 'Còn hạn',
        },
        {
          id: 2,
          label: 'Quá hạn',
        },
      ],
      formFilterShow: false,
      danhSachThuTucHanhChinh: [],
    }
  },
  computed: {
    ...mapGetters({
      permission: 'common/permissions',
    }),
    checkQuyenTraKetQuaNhieuHs() {
      return hasPermission([PERMISSION_NAME.HO_SO_TU_CHOI_GIAI_QUYET.TRA_KET_QUA_NHIEU_HS])
    },
    checkQuyenIn() {
      return hasPermission([PERMISSION_NAME.HO_SO_TU_CHOI_GIAI_QUYET.IN])
    },
    checkQuyenChiTiet() {
      return hasPermission([PERMISSION_NAME.HO_SO_TU_CHOI_GIAI_QUYET.CHI_TIET])
    },
    checkAllQuyen() {
      return [this.checkQuyenChiTiet, this.checkQuyenIn].every(item => item === false)
    },
    getColumns() {
      return this.checkAllQuyen ? this.columns.filter(item => item.field !== 'actions') : this.columns
    },
  },
  created() {
    this.filterDanhSachHoSo()
    this.getDanhSachThuTucHanhChinh()
  },
  methods: {
    formatDateTime,
    onQueryChange({ action, searchQuery, callback }) {
      if (action === 'ASYNC_SEARCH') {
        this.handleSearchQueryChange(searchQuery, callback)
      }
    },
    // eslint-disable-next-line func-names
    handleSearchQueryChange: _debounce(async function (query, callback) {
      const response = this.danhSachThuTucHanhChinh.filter(item => removeDiacritical(item.name).indexOf(removeDiacritical(query)) >= 0)
      callback(null, response)
    }, 100),
    traKetQua() {
      this.isShow = true
      this.$refs.commonModal.showModal()
    },
    handleOk() {
      this.$refs.commonModal.hideModal()
      this.titleGQHS = 'Thông báo'
      this.$refs.commonModalGQHS.showModal()
    },
    detailHoSo(data) {
      this.$router.push({
        path: '/ho-so/chi-tiet',
        query: { id: data.id, name: this.$route.name },
      })
    },
    getDanhSachThuTucHanhChinh() {
      this.$axios.get(END_POINTS.THU_TUC_HANH_CHINH.COMBOBOX, false).then(response => {
        if (response.data && response.data.succeeded) {
          this.danhSachThuTucHanhChinh = response.data.data
        }
      })
    },
    onPrint(data) {
      if (data?.loaiTrangThaiId) {
        this.$axios.get(`${END_POINTS.MAU_PHIEU.LAY_TRANG_THAI_ID}?loaiTrangThaiId=${data.loaiTrangThaiId}`).then(res => {
          if (res.data?.succeeded) {
            this.hoSo = data
            this.danhSachNhomPhieu = res.data.data
            this.$refs.printModal.showModal()
          }
        })
      } else {
        this.$toast.error('Hồ sơ không có loại trạng thái!')
      }
    },
    onClickToPrinter(dataMauPhieu) {
      this.$axios.get(`${END_POINTS.TIEP_NHAN_HO_SO_TRUC_TIEP.CHI_TIET}?id=${this.hoSo?.id}`).then(res => {
        if (res.data?.succeeded) {
          this.$refs.printModal.hideModal()
          const dataHoSo = res.data.data
          this.mauPhieu = dataMauPhieu
          const regEx = /\{{(.*?)\}}/g
          let match = null
          // eslint-disable-next-line no-cond-assign
          while ((match = regEx.exec(this.mauPhieu.duongDan)) !== null) {
            if (match[1]) {
              let format = dataHoSo[`${match[1]}`]
              if (match[0].indexOf('ngay') >= 0) {
                let typeDate = 'DD/MM/YYYY'
                let dateTransfer = dataHoSo[`${match[1]}`]
                if (match[1].indexOf('|') >= 0) {
                  const dateForm = match[1].split('|')
                  // eslint-disable-next-line prefer-destructuring
                  typeDate = dateForm[1]
                  // eslint-disable-next-line prefer-destructuring
                  dateTransfer = dataHoSo[`${dateForm[0]}`]
                }
                if (match[0].indexOf('ngayHienTai') >= 0) {
                  dateTransfer = new Date()
                }
                format = this.$print.formatFullDateTime(dateTransfer, typeDate)
              } else if (match[0].indexOf('hoSoGiayToKhacModels') >= 0) {
                format = this.$print.formatStringTable(dataHoSo[`${match[1]}`])
              } else if (match[0].indexOf('Models') >= 0) {
                format = this.$print.formatTable(dataHoSo[`${match[1]}`])
              } else if (match[0].indexOf('CodeBase64') >= 0) {
                format = this.$print.formatImg(dataHoSo[`${match[1]}`], match[0])
              } else if (['gioHenTraDen', 'gioHenTraTu'].includes(match[1])) {
                format = this.$print.formatGio(dataHoSo[`${match[1]}`])
              }
              this.mauPhieu.duongDan = this.mauPhieu.duongDan.replace(match[0], format || '')
            }
          }
          this.dataMauPhieu = dataHoSo
          this.$refs.InTheoBieuMau.show()
        }
      })
    },
    hide() {
      this.$refs.InTheoBieuMau.hide()
    },
    onClickToPrint() {
      this.$print.onClickToPrint(this)
    },
    onExportFileWord() {
      this.$print.onExportFileWord(this)
    },
    processMultipleDoc() {
      if (this.selectedRows && this.selectedRows.length !== 0) {
        this.$refs.xuLyHoSo.show(this.selectedRows.filter(item => !(item.isChoThuPhi || item.isCanThuPhi)))
      }
    },
    onSelectRow(rows) {
      this.selectedRows = rows
    },
    filterDanhSachHoSo(param = {}) {
      this.filter = {
        ...this.filter,
        ...param,
      }
      let payload = {
        ...this.filter,
        trangThaiConHanQuaHan: this.filter.trangThaiConHanQuaHan || 0,
        loaiTrangThaiConHanQuaHan: this.filter.loaiTrangThaiConHanQuaHan || 0,
      }
      if (this.$refs.hoSo) {
        payload = {
          ...payload,
          ...this.$refs.hoSo.getCurrentPage(),
        }
      }
      if (this.filter.trangThaiConHanQuaHan) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
        }
      }
      if (this.filter.loaiTrangThaiConHanQuaHan) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
        }
      }
      if (this.filter.dataTTHCId.length) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
        }
      }
      if (this.$refs.formFilter) {
        payload = {
          ...payload,
          ...this.$refs.formFilter.getFormFilter(),
        }
      }
      if (param.chuHoSo_HoVaTen) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
          chuHoSo: param.chuHoSo_HoVaTen ? param.chuHoSo_HoVaTen.replace(/\s+/g, ' ').trim() : '',
        }
      }
      if (param.code) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
          maHoSo: param.code ? param.code.replace(/\s+/g, ' ').trim() : '',
        }
      }
      if (param.chuHoSo_DiaChiDayDu) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
          diaChiChuHoSo: param.chuHoSo_DiaChiDayDu ? param.chuHoSo_DiaChiDayDu.replace(/\s+/g, ' ').trim() : '',
        }
      }
      this.$axios.post(END_POINTS.TIEP_NHAN_HO_SO_BO_SUNG.DANH_SACH_HO_SO_BO_SUNG, this.$trimObject({
        ...payload,
      }), false).then(response => {
        if (response.data && response.data.succeeded) {
          this.rows = response.data.data
          this.showCanThuPhi = response.data.data.isCanThuPhi
          this.showChoThuPhi = response.data.data.isChoThuPhi
          this.tongSoHoSo = response.data.totalCount || 0
          this.$refs.hoSo.resetCurrentPage(payload.pageNumber)
        }
      })
    },
    pageChange() {
      const data = this.$refs.hoSo.getCurrentPage()
      this.payload = {
        ...this.filter,
        trangThaiConHanQuaHan: this.filter.trangThaiConHanQuaHan || 0,
        loaiTrangThaiConHanQuaHan: this.filter.loaiTrangThaiConHanQuaHan || 0,
        pageNumber: data.pageNumber,
        pageSize: data.pageSize,
      }
      this.$axios
        .post(
          END_POINTS.TIEP_NHAN_HO_SO_BO_SUNG.DANH_SACH_HO_SO_BO_SUNG, this.payload, false,
        )
        .then(response => {
          if (response.data && response.data.succeeded) {
            this.rows = response.data.data
            this.showCanThuPhi = response.data.data.isCanThuPhi
            this.showChoThuPhi = response.data.data.isChoThuPhi
            this.tongSoHoSo = response.data.totalCount || 0
          }
        })
    },
    onUpdate(item) {
      this.form = {
        id: item.id,
        mahoSo: item.mahoSo,
        tenhoSo: item.tenhoSo,
        loaihoSoId: item.loaihoSoId,
        status: item.status,
      }
    },
    setItemUpdate(item) {
      this.selectedRows = [item]
    },
    onDelete() {},
    showFormFilter() {
      this.formFilterShow = !this.formFilterShow
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.name,
        label2: removeDiacritical(node.name),
      }
    },
    resetFilter() {
      this.filter = {
        dataTTHCId: [],
        dataLinhVucId: [],
        fulltextsearch: '',
        chuHoSo: '',
        maHoSo: '',
        diaChiChuHoSo: '',
        thoiGianTiepNhanTu: '',
        thoiGianTiepNhanDen: '',
        thoiGianHenTraTu: '',
        thoiGianHenTraDen: '',
        trangThaiConHanQuaHan: null,
        loaiTrangThaiConHanQuaHan: null,
        trangThaiHoSo: [],
        loaiTrangThaiHoSo: [],
        navCode: this.$route.meta.code,
        pageNumber: 1,
        pageSize: 10,
      }
    },
    clearTreeSelect() {
      setTimeout(() => {
        this.nextTickTreeSelect()
      }, 200)
      return true
    },
    nextTickTreeSelect() {
      this.$nextTick(() => {
        this.filterDanhSachHoSo()
      })
    },
    refreshCommon() {
      if (this.$refs.formFilter) {
        this.$refs.formFilter.resetForm()
      }
      this.$refs.hoSo.$refs.vbTables.reset()
      this.resetFilter()
      this.filterDanhSachHoSo()
    },
    checkRowStyle(data) {
      return (data.isCanThuPhi || data.isChoThuPhi) ? 'checked-column' : ''
    },
  },
}
</script>
<style lang="scss" scoped>
.input-icon {
  padding: 8px 0 8px 8px;
  border: 1px solid #d8d6de;
  border-right: none;
}

.input-group-icon {
  border-left: none;
}

.router-link-color {
  a {
    color: white;
  }
}

.custom-column {
  p {
    margin-bottom: 0;
  }
}
.warning-class{
  color: #DB353A;
}
.text-phi{
  margin-left:5px;
}
.text-top{
  margin-top:6px;
}
</style>
