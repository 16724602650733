<template>
  <ho-so-khong-giai-quyet />
</template>
<script>
import HoSoKhongGiaiQuyet from '@/modules/tra-ket-qua/components/pages/khong-giai-quyet/HoSoKhongGiaiQuyet.vue'

export default {
  components: {
    HoSoKhongGiaiQuyet,
  },
}
</script>
